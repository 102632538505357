<template>

  <div>

    <user-list-add-new
      :is-add-new-student-sidebar-active.sync="isAddNewUserSidebarActive"
      :methods="methodOptions"
      :paid-options="statusOptions"
      :payer-options="payerOptions.filter(el => el.id)"
      @refetch-data="refetchData"
    />

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->
    <registrations-filter
      :isFilterActive.sync="isFilterActive"
      :student.sync="student"
      :course.sync="course"
      :defaultStatus.sync="defaultStatus"
      :status-options="statusOptions.filter(status => status.name !== 'Abandoned checkout')"
      :visa.sync="visa"
      :who_pays.sync="who_pays"
      :payer-options="payerOptions"
      :regDefaultStatus.sync="regDefaultStatus"
      :regStatusOptions="regStatusOptions"
      :dateStart.sync="dateStart"
      :dateEnd.sync="dateEnd"
      :paymentFilter.sync="paymentFilter"
      :method-options="methodOptions"
      :isLoadingExcelDoc="isLoadingExcelDoc"
      @export-registration="dataMeta.of > 2000 ? $bvModal.show('export-registration') : getExcelDoc(student, course, dateStart, dateEnd, who_pays.id, defaultStatus.id, paymentFilter.id, regDefaultStatus.value, selectedRows, selectAll, unselectedRows)"
      @reset-filter="resetFilter"
    />
    <!-- <user-select-actions
      v-if="selectedRows.length !== 0"
      @multi-action="multiAction"
      @show-move-student-modal="$bvModal.show('move-student')"
      @cancel-registration-modal="$bvModal.show('cancel-registration-modal')"
      @show-send-email-modal="$bvModal.show('send-email')"
      @copy-emails="copyEmails(emailsList.join('; '))"
    /> -->

    <courses-list-big-modal
      v-if="isBigModalActive"
      :isBigModalActive.sync="isBigModalActive"
      :classrooms="classrooms"
      :teachers="teachers"
      :masters="masters"
      :TofDArray="TofDArray"
      :currentCourseData="currentCourseData"
      :msgs="msgs"
      :statusArray="statusArray"
      :UsersToAdd="UsersToAdd"
      :UsersAlreadyAdded="UsersAlreadyAdded"
      :currentUser="currentUser"
      :loading="spinner"
      @refetch-data="refetchData"
      @loading="loadSpinner"
      @suggest-classrooms="suggestClassrooms"
      @suggest-teacher="suggestTeacher"
    />
    <!-- :cities="cities" -->

    <!-- Table Container Card -->
    <!-- <b-card
      no-body
      :class="dashboard ? 'my-0 mx-2' : 'mt-1'"
    >
    </b-card> -->
    <b-card
      no-body
      class="mb-0"
    >

      <div :class="dashboard ? 'my-0 mx-2' : 'mt-2 mx-2'">
        <!-- Table Top -->
        <b-row v-if="!studentId">
          <b-col
            cols="6"
            :md="selectedRows.length > 0 ? '6' : '4'"
            class="mb-1"
            :order-md="selectedRows.length > 0 ? '0' : '1'"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                :placeholder="$t('Search...')"
                debounce="500"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="6"
            :md="selectedRows.length > 0 ? '6' : '4'"
            class="mb-1"
            :order-md="selectedRows.length > 0 ? '1' : '2'"
          >
            <div class="d-flex align-items-center justify-content-start">
              <table
                id="tblForm"
                border="0"
                cellpadding="5"
                cellspacing="0"
                style="width: 100%;"
              >
                <!-- <tr class="text-nowrap d-block"><td >Birth Date</td></tr> -->
                <tr><input
                  id="dobInput"
                  v-model="initialDate"
                  placeholder="Search by birth date..."
                  type="text"
                  class="date-format form-control"
                ></tr>
                <tr class="p-0">
                  <td class="p-0">
                    <small
                      class="error text-danger text-start"
                      style="display: none; color: #ea5455 !important"
                    >Invalid Date. Only dd/MM/yyyy format allowed.</small>
                  </td>
                </tr>
              </table>
            </div>
          </b-col>
          <b-col
            v-if="selectedRows.length > 0"
            cols="12"
            order-md="2"
          >
            <b-alert
              variant="secondary"
              show
            >
              <div class="alert-body text-center">
                <span>{{ selectAll ? dataMeta.of - unselectedRows.length : selectedRows.length }} entries are selected.</span>
                <b-Link
                  v-if="!selectAll"
                  @click="selectedRows = $store.state.registerStoreModule.registrations.rows; selectAll = true"
                >
                  Select all {{ dataMeta.of }} entries
                </b-Link>
                <b-Link
                  v-if="selectAll"
                  @click="selectAll = false; selectedRows = []"
                >
                  Clear selection
                </b-Link>
              </div>
            </b-alert>
          </b-col>
          <!-- filter status -->
          <b-col
            cols="12"
            :md="selectedRows.length > 0 ? '12' : '4'"
            :order-md="selectedRows.length > 0 ? '3' : '0'"
            class="d-flex flex-wrap align-items-center justify-content-between"
          >
            <div class="flex-grow-1 d-flex">
              <b-button
                v-if="selectedRows.length === 0"
                v-b-tooltip.hover.top="'Select all'"
                class="mr-50 mb-1"
                variant="primary"
                @click="selectAllRows"
              >
                <feather-icon icon="CheckSquareIcon" />
              </b-button>
              <b-button
                v-if="selectedRows.length > 0"
                v-b-tooltip.hover.top="'Clear selection'"
                class="mr-50 mb-1"
                variant="primary"
                @click="clearSelected"
              >
                <feather-icon icon="MinusSquareIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Filter'"
                variant="primary"
                class="d-inline-block mr-50 mb-1 flex-fill flex-sm-grow-0"
                @click="isFilterActive = true"
              >
                <feather-icon icon="FilterIcon" />
              </b-button>

              <b-button
                v-b-tooltip.hover.top="'Add'"
                variant="primary"
                class="d-inline-block mr-50 mb-1 flex-fill flex-sm-grow-0"
                @click="isAddNewUserSidebarActive = true"
              >
                <feather-icon icon="PlusIcon" />
              </b-button>
              <b-button
                v-b-tooltip.hover.top="'Export'"
                variant="primary"
                class="d-inline-block mb-1 flex-sm-grow-0 flex-fill text-nowrap"
                :disabled="isLoadingExcelDoc"
                @click="dataMeta.of > 2000 && (selectedRows.length === 0 || selectAll) ? $bvModal.show('export-registration') : getExcelDoc(student, course, dateStart, dateEnd, who_pays.id, defaultStatus.id, paymentFilter.id, regDefaultStatus.value, selectedRows, selectAll, unselectedRows)"
              >
                <b-spinner
                  v-if="isLoadingExcelDoc"
                  class="mr-25"
                  small
                  label="Loading..."
                />
                <feather-icon
                  v-if="!isLoadingExcelDoc"
                  class="ml-25"
                  icon="ExternalLinkIcon"
                />
              </b-button>
              <b-modal
                id="export-registration"
                title="Warning"
                centered
                ok-title="Yes"
                cancel-title="No"
                @ok="getExcelDoc(student, course, dateStart, dateEnd, who_pays.id, defaultStatus.id, paymentFilter.id, regDefaultStatus.value, selectedRows, selectAll, unselectedRows)"
              >
                <b-card-text>
                  <h5>
                    You are about to export a large dataset. This operation may take a while. Continue?
                  </h5>
                </b-card-text>
              </b-modal>
            </div>
            <user-select-actions
              v-if="selectedRows.length !== 0 && !isBusy"
              :key="selectedRows.length"
              :selected-rows="selectedRows"
              @multi-action="multiAction"
              @show-move-student-modal="$bvModal.show('move-student')"
              @show-update-payment-modal="payload = { invoice: {} }; $bvModal.show('update-payment')"
              @cancel-registration-modal="$bvModal.show('cancel-registration-modal')"
              @show-send-email-modal="$bvModal.show('send-email')"
              @copy-emails="copyEmails(emailsList.join('; '))"
              @proof-participation="multiAction('participation_proof')"
            />
          </b-col>
          <!-- Search -->
        </b-row>
        <b-row class="mb-2">
          <b-col>
            <v-select
              v-model="regDefaultStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="regStatusOptions"
              :clearable="false"
              class="mt-1"
            />
          </b-col>
          <b-col>
            <v-select
              v-model="defaultStatus"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions.filter(status => status.name !== 'Abandoned checkout')"
              :clearable="false"
              class="mt-1"
            />
          </b-col>
          <b-col>
            <v-select
              v-model="paymentFilter"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="methodOptions"
              :clearable="false"
              class="mt-1"
            />
          </b-col>
          <b-col>
            <v-select
              v-model="who_pays"
              label="name"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="payerOptions"
              :clearable="false"
              class="mt-1"
            />
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        key="1545"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="dashboard ? tableColumns1 : (studentId ? tableColumns2 : tableColumns)"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        :busy.sync="isBusy"
        :tbody-tr-class="tbodyRowClass"
        @row-clicked="onRowSelected"
      >
        <template #table-busy>
          <div
            v-if="isBusy"
            class="text-center d-flex align-items-center justify-content-center"
          >
            <b-spinner
              variant="primary"
              label="Text Centered"
            />
            <strong class="ml-1">Loading...</strong>
          </div>
        </template>
        <!-- Selected -->
        <!-- <template #cell(selected)="data">
          <b-form-checkbox
            v-model="selectedRows"
            :value="data.item.id"
          />
        </template> -->
        <template #cell(selected)="data">
          <b-form-checkbox
            disabled
            :checked="selectedRows.some(el => el.id === data.item.id)"
          >
            <feather-icon
              v-if="data.item.moved_from"
              v-b-popover.hover.top="`Moved from ${data.item.moved_from.course} (${data.item.moved_from.group})`"
              icon="CornerRightDownIcon"
              class="text-warning mr-1 b-avatar b-avatar-sm badge-light-warning rounded-circle p-25"
            />
            <feather-icon
              v-if="data.item.moved_to"
              v-b-popover.hover.top="`Moved to ${data.item.moved_to.course} (${data.item.moved_to.group})`"
              icon="CornerRightUpIcon"
              class="text-info mr-1 b-avatar b-avatar-sm badge-light-info rounded-circle p-25"
            />
          </b-form-checkbox>
          <small class="d-block mt-50 text-secondary">{{ data.item.id }}</small>
          <!-- <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template> -->
        </template>
        <!-- Column: student Id, not registration id -->
        <template #cell(date)="data">
          <div class="media-body">
            <div class="d-block text-nowrap text-capitalize p-0">
              {{ data.item.registration_date }}
            </div>
            <small class="text-muted text-nowrap">{{ data.item.registration_time }}</small>
          </div>

        </template>

        <!-- Column: visa -->
        <template #cell(course_group)="data">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-primary"
            class="pl-25"
            :disabled="!data.item.course_group"
            @click="ModalData(data.item.course_id, 'group')"
          >
            <b class="d-flex d-block text-nowrap">
              {{ data.item.course_group }}
            </b>
            <small class="d-flex mt-50 text-muted">
              {{ data.item.course_name }}
            </small>
          </b-button>
          <!-- style="cursor: pointer" -->
          <!-- @click="ModalData(data.item.course_id, 'group')" -->
          <!-- <b
            class="d-block"
          >
            {{ data.item.course_group }}
          </b>
          <small class="text-muted">
            {{ data.item.course_name }}
          </small> -->
        </template>

        <!-- Column: student name & visa -->
        <template #cell(student)="data">
          <div class="media-body">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="flat-primary"
              :to="{ name: 'apps-users-view', params: { id: data.item.student_id } }"
              class="font-weight-bold d-block text-nowrap text-capitalize p-25"
              :style="[data.item.waiting_list ? {'postition': 'relative', 'color': '#005160', 'background-color': '#cff4fc', 'border-color': '#b6effb'} : {}]"
            >
              <span class="d-flex">
                {{ data.item.student_name ? data.item.student_name.substring(0,20) + (data.item.student_name.length >= 20 ? '...' : '') : '' }}
              </span>
              <div class="d-flex align-items-center">
                <small
                  class="d-block mr-1 d-flex text-muted"
                  style="color: #055160"
                >{{ data.item.student_id }}</small>
                <small
                  v-if="data.item.nationality"
                  class="mr-50 d-flex text-muted"
                >
                  <span class="badge text-capitalize badge-light-secondary badge-pill">{{ data.item.nationality }}</span>
                </small>
              </div>
            </b-button>
          </div>
        </template>
        <!-- Column: who pays -->
        <template #cell(who_pays)="data">

          <div class="media-body">
            <div class="text-capitalize text-nowrap pr-50">
              <span v-if="data.item.payer">{{ data.item.payer.name }}</span>
            </div>
            <!-- <b-badge
              pill
              :variant="`light-${resolveUserStatusVariant(data.item.payer_confirmed)}`"
              class="text-capitalize"
              style="font-size: 10px"
            >
              {{ data.item.payer_confirmed ? 'Payer Confirmed' : 'Not confirmed' }}
            </b-badge> -->
          </div>
        </template>
        <template #cell(payment_method)="data">

          <div class="media-body">
            <div class="d-block text-nowrap text-capitalize">
              {{ data.item.paimentMethod ? data.item.paimentMethod.name : '' }}
            </div>
          </div>
        </template>
        <!-- Column: who pays -->
        <template #cell(payment_status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.registrationFilter ? data.item.registrationFilter.name : '')}`"
            class="text-capitalize"
            :style="`font-size: 10px;  opacity: ${data.item.extra_data && data.item.extra_data.refund && data.item.extra_data.refund.full === true ? '.99' : '.7'}`"
            v-b-tooltip.hover.top="data.item.extra_data && data.item.extra_data.refund && data.item.extra_data.refund.full === false ?  'partial ' + (data.item.extra_data && data.item.extra_data.refund && data.item.extra_data.refund.amount ? data.item.extra_data.refund.amount : '') +' '+ $store.state.appConfig.currency : '' "
          >
            {{ data.item.registrationFilter ? data.item.registrationFilter.name : '' }}
          </b-badge>
          <small
            v-if="data.item.extra_data
              && data.item.extra_data.refund
              && data.item.extra_data.refund.refund_failed
              && data.item.extra_data.refund.refund_failed.data
              && data.item.extra_data.refund.refund_failed.data.error"
            v-b-tooltip.hover.top="data.item.extra_data
              && data.item.extra_data.refund
              && data.item.extra_data.refund.refund_failed
              && data.item.extra_data.refund.refund_failed.data
              && data.item.extra_data.refund.refund_failed.data.error ? data.item.extra_data.refund.refund_failed.data.error.message : ''"
            class="d-block text-danger text-nowrap ml-25 p-25"
          >
            Refund Failed
          </small>
          <!-- <span
            v-if="!data.item.paid"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.top="'Awaiting payment'"
              class="d-flex align-items-center justify-content-center"
            >
              <svg
                enable-background="new 0 0 700 700"
                class="ml-25"
                width="23"
                height="26"
                version="1.1"
                viewBox="0 0 700 700"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m422.86 173.64c-0.617 5.57-0.934 11.233-0.934 16.969 0 5.738 0.316 11.397 0.934 16.969h-351.64c-15.621 0-28.285 12.664-28.285 28.285v282.83c0 15.621 12.664 28.281 28.285 28.281h458.18c15.621 0 28.285-12.66 28.285-28.281v-176.29c5.57 0.613 11.233 0.93 16.969 0.93s11.398-0.316 16.969-0.93v176.29c0 34.363-27.854 62.223-62.223 62.223h-458.18c-34.363 0-62.223-27.858-62.223-62.223v-282.83c0-34.363 27.858-62.223 62.223-62.223h351.64zm17.902 90.504c14.613 26.551 36.891 48.285 63.854 62.223h-461.68v-62.223h397.82zm-397.83 62.223v-62.223 62.223zm531.72-254.55c65.605 0 118.79 53.184 118.79 118.79 0 65.605-53.184 118.79-118.79 118.79-65.605 0-118.79-53.185-118.79-118.79 0-65.606 53.184-118.79 118.79-118.79zm16.968 39.598c0-9.371-7.598-16.969-16.969-16.969s-16.969 7.598-16.969 16.969v92.066l61.733 61.733c6.625 6.625 17.371 6.625 23.996 0 6.629-6.629 6.629-17.37 0-24l-51.793-51.793 2e-3 -78.006z"
                  fill="#6e6b7b"
                />
              </svg>
            </div>
          </span>
          <span
            v-if="data.item.paid"
            v-b-tooltip.hover.top="'Paid'"
            class="b-avatar ml-1 badge-light-success rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="CreditCardIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
        </template>
        <template #cell(status)="data">
          <!-- <span
            v-if="data.item.registration_status === 'awaiting payment'"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <div
              v-b-tooltip.hover.top="data.item.registration_status"
              class="d-flex align-items-center justify-content-center"
            >
              <svg
                enable-background="new 0 0 700 700"
                class="ml-25"
                width="23"
                height="26"
                version="1.1"
                viewBox="0 0 700 700"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="m422.86 173.64c-0.617 5.57-0.934 11.233-0.934 16.969 0 5.738 0.316 11.397 0.934 16.969h-351.64c-15.621 0-28.285 12.664-28.285 28.285v282.83c0 15.621 12.664 28.281 28.285 28.281h458.18c15.621 0 28.285-12.66 28.285-28.281v-176.29c5.57 0.613 11.233 0.93 16.969 0.93s11.398-0.316 16.969-0.93v176.29c0 34.363-27.854 62.223-62.223 62.223h-458.18c-34.363 0-62.223-27.858-62.223-62.223v-282.83c0-34.363 27.858-62.223 62.223-62.223h351.64zm17.902 90.504c14.613 26.551 36.891 48.285 63.854 62.223h-461.68v-62.223h397.82zm-397.83 62.223v-62.223 62.223zm531.72-254.55c65.605 0 118.79 53.184 118.79 118.79 0 65.605-53.184 118.79-118.79 118.79-65.605 0-118.79-53.185-118.79-118.79 0-65.606 53.184-118.79 118.79-118.79zm16.968 39.598c0-9.371-7.598-16.969-16.969-16.969s-16.969 7.598-16.969 16.969v92.066l61.733 61.733c6.625 6.625 17.371 6.625 23.996 0 6.629-6.629 6.629-17.37 0-24l-51.793-51.793 2e-3 -78.006z"
                  fill="#6e6b7b"
                />
              </svg>
            </div>
          </span> -->
          <span
            v-if="data.item.status === 'pending'"
            v-b-tooltip.hover.top="'Pending confirmation'"
            class="b-avatar badge-light-warning rounded-circle"
          >
            <feather-icon
              icon="LoaderIcon"
              class="text-body"
            />
          </span>
          <span
            v-if="data.item.status === 'confirmed'"
            v-b-tooltip.hover.top="'Confirmed'"
            class="b-avatar badge-light-success rounded-circle"
          >
            <feather-icon
              icon="CheckCircleIcon"
              class="text-body"
            />
          </span>
          <!-- <span
            v-if="data.item.registration_status === 'pending confirmation'"
            v-b-tooltip.hover.top="data.item.registration_status"
            class="b-avatar ml-1 badge-light-warning rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <!-- <span
            v-if="data.item.status === 'Cancellation pending approval'"
            v-b-tooltip.hover.top="data.item.registration_status"
            class="b-avatar ml-1 badge-light-danger rounded-circle d-flex align-items-center justify-content-center"
          >
            <span class="b-avatar-custom">
              <feather-icon
                icon="LoaderIcon"
                size="16"
                class="align-middle text-body"
              />
            </span>
          </span> -->
          <span
            v-if="data.item.status === 'canceled'"
            v-b-tooltip.hover.top="'Canceled'"
            class="b-avatar badge-light-danger rounded-circle"
          >
            <feather-icon
              icon="SlashIcon"
              class="align-middle text-body"
            />
          </span>
        </template>

        <!-- Column: who pays -->
        <template #cell(attendance)="data">
          <b-badge
            pill
            :variant="`light-primary`"
            class="text-capitalize"
          >
            {{ data.item.attendance }}%
          </b-badge>
        </template>
        <template #cell(comment)="data">
          <b-button
            :id="`popover-target-${data.item.id}`"
            variant="flat-primary"
            size="sm"
            class="btn-icon rounded-circle"
            @click="handleChatClick({model:'registration', id: data.item.id})"
          >
            <feather-icon
              icon="ClipboardIcon"
              class="align-middle text-body"
              badge-classes="bg-danger"
              :badge="data.item.comments_number > 0 ? data.item.comments_number : ''"
              size="16"
            />
          </b-button>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="data.item.deleted_at"
            variant="primary"
            size="sm"
            @click="restoreReg(data.item.id)"
          >
            Restore
          </b-button>
          <b-dropdown
            v-if="!data.item.deleted_at"
            dropleft
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleChatClick1(data.item.student_id)">
              <feather-icon icon="MessageSquareIcon" />
              <span class="align-middle ml-50">Contact student</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal7Id(data.item.id)"
              @click="getData(data.item)"
            >
              <feather-icon icon="CreditCardIcon" />
              <span class="align-middle ml-50">Update payment</span>
            </b-dropdown-item>
            <!-- {{ data.item.registrationFilter }} -->
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'unpaid' || data.item.registrationFilter.code === 'to_be_refunded'"
              @click="markAs(data.item.invoice.id, 1)"
            >
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">Mark as paid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'paid'"
              @click="markAs(data.item.invoice.id, 2)"
            >
              <feather-icon icon="LoaderIcon" />
              <span class="align-middle ml-50">Mark as unpaid</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'confirmed' && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal2Id(data.item.id)"
            >
              <feather-icon icon="CheckIcon" />
              <span class="align-middle ml-50">{{ $t('Confirm registration') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.status !== 'canceled'"
              v-b-modal="getModal4Id(data.item.id)"
            >
              <feather-icon icon="XIcon" />
              <span class="align-middle ml-50">{{ $t('Cancel registration') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal3Id(data.item.id)"
              @click="getPlacementTest"
            >
              <feather-icon icon="SendIcon" />
              <span class="align-middle ml-50">{{ $t('Send Placement test') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.waiting_list && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              @click="regToWaitingList(data.item.id)"
            >
              <feather-icon icon="ClipboardIcon" />
              <span class="align-middle ml-50">{{ $t('Add to waiting list') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.visa_needed && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              @click="sendAdmissionLetter(data.item.id)"
            >
              <feather-icon icon="FeatherIcon" />
              <span class="align-middle ml-50">{{ $t('Send admission letter') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.paimentMethod && (data.item.paimentMethod.name === 'Subsidy (IMDI)' || data.item.paimentMethod.name === 'Subsidy (UKR)')"
              @click="proofParticipation(data.item.id)"
            >
              <feather-icon icon="ThumbsUpIcon" />
              <span class="align-middle ml-50">{{ $t('Send proof of participation') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="parseInt(data.item.attendance, 0) > 80"
              @click="generateCertificate(data.item.id)"
            >
              <feather-icon icon="AwardIcon" />
              <span class="align-middle ml-50">{{ $t('Generate certificate') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              @click="generateContract(data.item.id)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('Generate contract') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.moved_to_id && data.item.status === 'confirmed' && data.item.registrationFilter && data.item.registrationFilter.code !== 'refunded'"
              v-b-modal="getModal5Id(data.item.id)"
            >
              <feather-icon icon="MoveIcon" />
              <span class="align-middle ml-50">{{ $t('Move student') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-b-modal="getModal6Id(data.item.id)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">{{ ('Send email') }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                parseInt(data.item.course_price) !== 0
                  && data.item.registrationFilter
                  && (data.item.registrationFilter.code === 'paid' || data.item.registrationFilter.code === 'to_be_refunded')"
              @click="$bvModal.show(`refundModal${data.item.id}`)"
            >
              <feather-icon icon="DollarSignIcon" />
              <span class="align-middle ml-50">{{ 'Refund' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="
                parseInt(data.item.course_price) !== 0
                  && data.item.registrationFilter
                  && (data.item.registrationFilter.code === 'paid')"
              @click="$bvModal.show(`tobeRefundedModal${data.item.id}`)"
            >
              <feather-icon icon="CornerUpLeftIcon" />
              <span class="align-middle ml-50">{{ 'To be refunded' }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.deleted_at"
              v-b-modal="getModal1Id(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t('Delete') }}</span>
            </b-dropdown-item>
            <!-- Refund modal -->
            <b-modal
              :id="'tobeRefundedModal'+data.item.id"
              :title="`Set status to 'To be refunded' for ${data.item.course_code} (${data.item.course_group})`"
              ok-title="To be refunded"
              :ok-disabled="!refundReason"
              centered
              size="lg"
              no-close-on-backdrop
              @show="refundReason = null; isTobeRefunded = false"
              @ok="tobeRefunded(data.item.id, data.item.status); isTobeRefunded = true"
            >
              <b-row>
                <b-col cols="4">
                  <b-form-group
                    label="Student name"
                    label-for="student-name"
                  >
                    <b-form-input
                      v-model="data.item.student_name"
                      placeholder="Student name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Registration id"
                    label-for="registration-id"
                  >
                    <b-form-input
                      v-model="data.item.id"
                      placeholder="Registration id"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group
                    label="Registration status"
                    label-for="registration-status"
                  >
                    <b-form-input
                      v-model="data.item.status"
                      placeholder="Registration status"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Reason for Refund"
                    label-for="registration-id"
                  >
                    <b-form-textarea
                      v-model="refundReason"
                      placeholder="Reason for Refund"
                      rows="1"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
            <!-- Refund modal -->
            <b-modal
              :id="'refundModal'+data.item.id"
              :title="`Refund Details for ${data.item.course_code} (${data.item.course_group})`"
              ok-title="Refund"
              :ok-disabled="!refundAmount && !refundFull || parseInt(refundAmount)>parseInt(data.item.course_price)"
              centered
              size="lg"
              no-close-on-backdrop
              @ok="submitRefund(data.item.id, data.item.status)"
              @show="refundAmount = null
                     refundReason = data.item.extra_data
                       && data.item.extra_data.refund
                       && data.item.extra_data.refund.to_be_refunded_reason ? data.item.extra_data.refund.to_be_refunded_reason : null
                     refundFull = false
                     refundComments = null"
            >
              <b-row>
                <b-col cols="6">
                  <b-form-group
                    label="Student name"
                    label-for="student-name"
                  >
                    <b-form-input
                      v-model="data.item.student_name"
                      placeholder="Student name"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Registration id"
                    label-for="registration-id"
                  >
                    <b-form-input
                      v-model="data.item.id"
                      placeholder="Registration id"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Registration status"
                    label-for="registration-status"
                  >
                    <b-form-input
                      v-model="data.item.status"
                      placeholder="Registration status"
                      disabled
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group
                    label="Amount"
                    label-for="amount"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-input
                        v-model="refundAmount"
                        placeholder="Amount"
                        :disabled="refundFull"
                        @keypress="validateInput"
                      />
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        :variant="refundFull ? 'success' : 'secondary'"
                        class="text-nowrap ml-50"
                        @click="refundFull = !refundFull; refundFull ? refundAmount = parseInt(data.item.course_price, 0).toFixed(2) : refundAmount = null"
                      >
                        <feather-icon
                          v-if="refundFull && data.item.course_price"
                          icon="CheckCircleIcon"
                        />
                        Full refund {{ parseInt(data.item.course_price, 0).toFixed(2) }} {{ $store.state.appConfig.currency }}
                      </b-button>
                      <!-- <b-form-checkbox
                        v-model="refundFull"
                        class="text-nowrap"
                      >
                        Full refund
                      </b-form-checkbox> -->
                    </div>
                    <small
                      v-if="parseInt(refundAmount) >parseInt(data.item.course_price)"
                      class="text-danger"
                    >Amount must be less than {{ parseInt(data.item.course_price, 0).toFixed(2) }}</small>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="refundReason"
                  cols="12"
                >
                  <b-form-group
                    label="Reason for Refund"
                    label-for="refund-reason"
                  >
                    <p class="mb-2">
                      {{ refundReason }}
                    </p>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group
                    label="Comments"
                    label-for="refund-comments"
                  >
                    <b-form-textarea
                      v-model="refundComments"
                      placeholder="Write your comments..."
                      rows="1"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
            <!-- send email modal -->
            <b-modal
              :id="'modal6'+data.item.id"
              :title="$t('Send email')"
              :ok-title="$t('Send')"
              centered
              size="lg"
              no-close-on-backdrop
              :ok-disabled="!emailValueSubject || !emailValueBody"
              @show="emailValueSubject = null; emailValueBody = null"
              @ok="sendEmailAction([data.item.id])"
            >
              <b-form-group
                label="Email subject"
                label-for="email-subject"
              >
                <b-form-input
                  v-model="emailValueSubject"
                  placeholder="Email subject"
                  type="text"
                />
              </b-form-group>
              <b-form-group
                label="Email body"
                label-for="email-body"
                style="height: 260px"
              >
                <quill-editor
                  v-model="emailValueBody"
                  style="height: 170px"
                  :options="{ modules: {} }"
                />
              </b-form-group>
            </b-modal>
            <!-- basic modal -->
            <b-modal
              :id="'modal1'+data.item.id"
              :title="$t('Delete Registration')"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="remove(data.item.id)"
            >
              <b-card-text>
                <h5>{{ $t('Are you sure you want to delete') }} <strong>{{ data.item.student_name }}</strong></h5> registration from <strong>{{ data.item.course_name }}?</strong>
              </b-card-text>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal2'+data.item.id"
              title="Confirm registration"
              ok-title="Yes"
              cancel-title="No"
              centered
              @ok="confirm(data.item.id)"
            >
              <b-card-text>
                <h5>Are you sure you want to confirm <strong>{{ data.item.student_name }}</strong>'s registration on course <strong>{{ data.item.course_group }}</strong>?</h5>
              </b-card-text>
            </b-modal>
            <b-modal
              :id="'modal3'+data.item.id"
              title="Send placement test"
              :ok-title="$t('Send')"
              cancel-title="Cancel"
              centered
              size="lg"
              no-close-on-backdrop
              @ok="sendPTest([data.item.student_id], selectedTest.id, due_date)"
            >
              <div
                v-if="!isLoadedTests"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div v-if="isLoadedTests">
                <b-form-group
                  :label="$t('Test')"
                  label-for="test"
                >
                  <v-select
                    v-model="selectedTest"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="tests"
                    :clearable="false"
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Due date')"
                  label-for="due-date"
                >
                  <flat-pickr
                    v-model="due_date"
                    class="form-control"
                    name="date"
                    :placeholder="$t('Due date')"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i:ss', locale: { firstDayOfWeek: 1 }}"
                  />
                </b-form-group>
              </div>
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal4'+data.item.id"
              title="Confirm Cancelation"
              :ok-title="$t('Yes')"
              cancel-title="No"
              centered
              @ok="cancel(data.item.id, sendEmail)"
              @hidden="refundCancel || isTobeRefunded ? refetchData() : ''; isTobeRefunded = false"
              @show="sendEmail = false; tobeRefundedCancel = false; cancelRefund = false"
            >
              <h5 class="mb-1">
                Do you want to cancel <strong>{{ data.item.student_name }}</strong>'s registration on course <strong class="text-nowrap">{{ data.item.course_code }} {{ data.item.course_group ? `(${data.item.course_group})` : null }}</strong>?
              </h5>
              <div class="d-flex justify-content-start mb-2">
                <b-form-checkbox
                  v-model="sendEmail"
                >
                  Send email
                </b-form-checkbox>
                <div
                  v-if="!refundCancel
                    && data.item.registrationFilter
                    && (data.item.registrationFilter.code === 'paid' || data.item.registrationFilter.code === 'to_be_refunded')
                    && parseInt(data.item.course_price) !== 0
                    && !isTobeRefunded"
                  class="d-flex justify-content-start"
                >
                  <span class="ml-1">|</span>
                  <b-form-checkbox
                    v-model="cancelRefund"
                    class="ml-1"
                    @change="tobeRefundedCancel = false"
                  >
                    Refund
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="data.item.registrationFilter && data.item.registrationFilter.code === 'paid'"
                    v-model="tobeRefundedCancel"
                    class="ml-1"
                    @change="cancelRefund = false"
                  >
                    To be refunded
                  </b-form-checkbox>
                </div>
              </div>
              <!-- <b-alert
                v-if="refundCancel"
                class="p-1"
                show
                style="background: #ffe9d9 !important"
              >
                <div class="alert-body">
                  <feather-icon
                    icon="InfoIcon"
                    class="mr-25 text-danger"
                  />
                  <span class="text-secondary">
                    Attention! A refund for this registration is currently in process.
                  </span>
                </div>
              </b-alert> -->
            </b-modal>

            <!-- basic modal -->
            <b-modal
              :id="'modal5'+data.item.id"
              :title="$t('Move Student')"
              ok-title="Move"
              centered
              no-close-on-backdrop
              @show="selectedCourse = {}"
              @ok="moveStudent(data.item.id)"
            >
              <b-card-text>
                <b-form-group
                  :label="$t('Course')"
                  label-for="course"
                >
                  <v-select
                    v-model="selectedCourse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="courses"
                    @keyup.native="autoCompleteCourse($event.target.value)"
                    @click.native="autoCompleteCourse('')"
                  >
                    <span slot="no-options">
                      <div
                        v-if="isTypingCourse"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        <b-spinner
                          variant="primary"
                          label="Text Centered"
                        />
                      </div>
                      <div
                        v-if="!isTypingCourse && !courses.length"
                        class="text-center d-flex align-items-center justify-content-center"
                      >
                        No matching records found
                      </div>
                    </span>
                    <template #selected-option="{ code }">
                      <span>{{ code }}</span>
                    </template>
                    <template #option="{ code, group, date_start }">
                      <div class="d-flex justify-content-between">
                        <span> {{ code }} <small
                          v-if="group"
                          class="text-secondary"
                        >({{ group }})</small></span>
                        <small>{{ date_start }}</small>
                      </div>
                    </template>
                  </v-select>
                </b-form-group>
              </b-card-text>
            </b-modal>
            <!-- Update payment -->
            <b-modal
              :id="'modal7'+data.item.id"
              :title="('Update payment')"
              :ok-title="$t('Edit')"
              hide-footer
              size="lg"
              centered
              no-close-on-backdrop
            >
              <validation-observer
                #default="{ handleSubmit }"
                ref="form"
              >
                <!-- Form -->
                <b-form
                  class="p-2"
                  @submit.prevent="handleSubmit(updatePayment(payload))"
                  @reset.prevent="resetForm"
                >
                  <b-row>
                    <!-- Student -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Student"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Student')"
                          label-for="student"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.student_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="students"
                            :clearable="false"
                            disabled
                            @keyup.native="autoCompleteStudent($event.target.value, 'student')"
                            @click.native="autoCompleteStudent('', 'student')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingStudent"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingStudent && !students.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Course -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="course"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Course')"
                          label-for="course"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.course_name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :clearable="false"
                            :options="courses"
                            disabled
                            @keyup.native="autoCompleteCourse($event.target.value)"
                            @click.native="autoCompleteCourse('')"
                          >
                            <span slot="no-options">
                              <div
                                v-if="isTypingCourse"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                <b-spinner
                                  variant="primary"
                                  label="Text Centered"
                                />
                              </div>
                              <div
                                v-if="!isTypingCourse && !courses.length"
                                class="text-center d-flex align-items-center justify-content-center"
                              >
                                No matching records found
                              </div>
                            </span>
                          </v-select>
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Sent date"
                      >
                        <b-form-group
                          :label="$t('Sent date')"
                          label-for="example-datePicker2"
                          :state="getValidationState(validationContext)"
                        >
                          <flat-pickr
                            v-model="payload.invoice.sent_date"
                            class="form-control sent-date"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                            :placeholder="$t('Sent at...')"
                            disabled
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Sent date -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Paid date"
                      >
                        <b-form-group
                          label-for="example-datePicker3"
                          :label="$t('Paid date')"
                        >
                          <flat-pickr
                            v-model="payload.paid_date"
                            class="form-control"
                            name="date"
                            :config="{ altFormat: 'd-m-Y', altInput: true}"
                            :placeholder="$t('Paid at...')"
                            @input="errors.paid_date = []"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                          <small
                            v-if="errors"
                            class="text-danger"
                          >{{ errors.paid_date[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Method -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment method"
                        label-for="payment-method"
                      >
                        <v-select
                          v-model="payload.paimentMethod"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="methodOptions.filter(el => el.id)"
                          label="name"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Paiment status -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <b-form-group
                        label="Payment status"
                        label-for="payment-status"
                      >
                        <v-select
                          v-model="payload.registrationFilter"
                          label="name"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="statusOptions.filter(el => el.id && !el.filtered)"
                          :clearable="false"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- Who pays -->
                    <b-col
                      cols="12"
                      sm="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="Payer"
                      >
                        <b-form-group
                          label="Payer"
                          label-for="who-pays"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="payload.who_pays"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="payerOptions.filter(el => el.id)"
                            :clearable="false"
                            @input="payload.payer_name = null; payload.payer_email = null; payload.payer_address = null; payload.company_number; payload.company_number; payload.responsible = null; payload.payer_tel = null"
                          />
                          <b-form-invalid-feedback :state="getValidationState(validationContext)">
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Payer name -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'company' || payload.who_pays.code === 'payer')"
                        #default="validationContext"
                        name="Payer name"
                      >
                        <b-form-group
                          :label="('Payer name')"
                          label-for="payer-name"
                        >
                          <b-form-input
                            v-model="payload.payer_name"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Payer name')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Payer address -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'company' || payload.who_pays.code === 'payer')"
                        #default="validationContext"
                        name="Invoicing address"
                      >
                        <b-form-group
                          :label="$t('Invoicing address')"
                          label-for="invoicing-address"
                        >
                          <b-form-input
                            v-model="payload.payer_address"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Invoicing address')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Company number -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Company org.number"
                      >
                        <b-form-group
                          :label="$t('Company org.number')"
                          label-for="Company-org.number"
                        >
                          <b-form-input
                            v-model="payload.company_number"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Company org.number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Responsible -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && payload.who_pays.code === 'company'"
                        #default="validationContext"
                        name="Responsible / contact person"
                      >
                        <b-form-group
                          :label="('Responsible / contact person')"
                          label-for="Resposible-contact-person"
                        >
                          <b-form-input
                            v-model="payload.responsible"
                            autofocus
                            :state="getValidationState(validationContext)"
                            :placeholder="('Responsible / contact person')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Phone number -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'company' || payload.who_pays.code === 'payer')"
                        #default="validationContext"
                        name="Phone number"
                      >
                        <b-form-group
                          :label="('Phone number')"
                          label-for="Phone-number"
                        >
                          <b-form-input
                            v-model="payload.payer_tel"
                            autofocus
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Phone number')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Email address -->
                    <b-col
                      cols="12"
                      sm="6"
                    >
                      <validation-provider
                        v-if="payload.who_pays && (payload.who_pays.code === 'company' || payload.who_pays.code === 'payer')"
                        #default="validationContext"
                        name="Email address"
                        rules="email"
                      >
                        <b-form-group
                          :label="('Email address')"
                          label-for="Email-address"
                        >
                          <b-form-input
                            v-model="payload.payer_email"
                            autofocus
                            type="email"
                            :state="getValidationState(validationContext)"
                            trim
                            :placeholder="('Email address')"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <!-- Status -->
                  <!-- <b-form-group
                      :label="$t('Status')"
                      label-for="status"
                    >
                      <v-select
                        v-model="data.item.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :clearable="false"
                      />
                    </b-form-group> -->

                  <!-- Form Actions -->
                  <div class="d-flex mt-2">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-2"
                      type="submit"
                    >
                      {{ $t('Update') }}
                    </b-button>
                  </div>

                </b-form>
              </validation-observer>
            </b-modal>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('entries') }}</label>
          </b-col>

          <b-col
            cols="12"
            sm="3"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Showing') }} {{ dataMeta.from }} {{ $t('to') }} {{ dataMeta.to }} {{ $t('of') }} {{ dataMeta.of }} {{ $t('entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="!isBusy"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <div
      v-if="isChatActive"
      class="notesModule shadow-lg"
    >
      <div
        style="background: #fff"
        class="pb-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="light"
          size="sm"
          class="btn-icon shadow-sm ml-auto "
          style="display: block; transform: translate(8px, -2px);"
          @click="isChatActive = false"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      <chat-module
        :key="chatModelId.id"
        :model-id="chatModelId.id"
        model-type="registration"
        @decrease-notes="refetchData"
        @notes-increament="refetchData"
      />
    </div>
    <div
      v-if="isChatActive1"
      class="commentschats"
    >
      <feather-icon
        icon="XSquareIcon"
        size="22"
        class="align-middle text-body close-btn"
        @click="isChatActive1 = false"
      />
      <chat-module
        :conversation="conversation"
        :messages="messages"
      />
    </div>
    <!-- send email modal -->
    <b-modal
      id="send-email"
      :title="'Send email lkfjdskljfdsk'"
      :ok-title="$t('Send')"
      centered
      size="lg"
      no-close-on-backdrop
      :ok-disabled="!emailValueSubject || !emailValueBody"
      @show="emailValueSubject = null; emailValueBody = null;"
      @ok="multiAction('send-email')"
    >
      <b-form-group
        label="Email subject"
        label-for="email-subject"
      >
        <b-form-input
          v-model="emailValueSubject"
          placeholder="Email subject"
          type="text"
        />
      </b-form-group>
      <b-form-group
        label="Email body"
        label-for="email-body"
        style="height: 260px"
      >
        <quill-editor
          v-model="emailValueBody"
          style="height: 170px"
          :options="{ modules: {} }"
        />
      </b-form-group>
    </b-modal>
    <b-modal
      id="cancel-registration-modal"
      title="Cancel Registration"
      :ok-title="$t('Yes')"
      cancel-title="No"
      centered
      @ok="multiAction('cancel-registration', sendEmail)"
    >
      <b-card-text>
        <h5>{{ 'Are you sure you want to cancel these registrations' }}</h5>
      </b-card-text>
      <b-form-checkbox
        v-model="sendEmail"
      >
        Send email
      </b-form-checkbox>
    </b-modal>
    <b-modal
      id="move-student"
      :title="$t('Move Student')"
      :ok-title="$t('Move')"
      centered
      no-close-on-backdrop
      @ok="multiAction('move-student')"
    >
      <b-card-text>
        <b-form-group
          :label="$t('Course')"
          label-for="course"
        >
          <v-select
            v-model="selectedCourse"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="courses"
            @keyup.native="autoCompleteCourse($event.target.value)"
            @click.native="autoCompleteCourse('')"
          >
            <span slot="no-options">
              <div
                v-if="isTypingCourse"
                class="text-center d-flex align-items-center justify-content-center"
              >
                <b-spinner
                  variant="primary"
                  label="Text Centered"
                />
              </div>
              <div
                v-if="!isTypingCourse && !courses.length"
                class="text-center d-flex align-items-center justify-content-center"
              >
                No matching records found
              </div>
            </span>
            <template #selected-option="{ code }">
              <span>{{ code }}</span>
            </template>
            <template #option="{ code, group, date_start }">
              <div class="d-flex justify-content-between">
                <span> {{ code }} <small
                  v-if="group"
                  class="text-secondary"
                >({{ group }})</small></span>
                <small>{{ date_start }}</small>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-card-text>
    </b-modal>
    <!-- Update payment -->
    <b-modal
      id="update-payment"
      :title="('Update payment')"
      :ok-title="$t('Edit')"
      hide-footer
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="form"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(multiAction('update-payment'))"
          @reset.prevent="resetForm"
        >
          <b-row>
            <!-- Sent date -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Sent date"
              >
                <b-form-group
                  :label="$t('Sent date')"
                  label-for="example-datePicker2"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    v-model="payload.invoice.sent_date"
                    class="form-control sent-date"
                    name="date"
                    :config="{ altFormat: 'd-m-Y', altInput: true, locale: { firstDayOfWeek: 1 } }"
                    :placeholder="$t('Sent at...')"
                    disabled
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Sent date -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                #default="validationContext"
                name="Paid date"
              >
                <b-form-group
                  label-for="example-datePicker3"
                  :label="$t('Paid date')"
                >
                  <flat-pickr
                    v-model="payload.paid_date"
                    class="form-control"
                    name="date"
                    :config="{ altFormat: 'd-m-Y', altInput: true}"
                    :placeholder="$t('Paid at...')"
                    @input="errors.paid_date = []"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="errors"
                    class="text-danger"
                  >{{ errors.paid_date[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Payer -->
            <b-col
              cols="12"
              sm="4"
            >
              <validation-provider
                #default="validationContext"
                name="Payer"
              >
                <b-form-group
                  label="Payer"
                  label-for="who-pays"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="payload.who_pays"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="payerOptions.filter(el => el.id)"
                    :clearable="false"
                    @input="payload.payer_name = null; payload.payer_email = null; payload.payer_address = null; payload.company_number; payload.company_number; payload.responsible = null; payload.payer_tel = null"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Payer name -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code !== 'student'"
                #default="validationContext"
                name="Payer name"
              >
                <b-form-group
                  :label="('Payer name')"
                  label-for="payer-name"
                >
                  <b-form-input
                    v-model="payload.payer_name"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :placeholder="('Payer name')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Payer address -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code !== 'student'"
                #default="validationContext"
                name="Invoicing address"
              >
                <b-form-group
                  :label="$t('Invoicing address')"
                  label-for="invoicing-address"
                >
                  <b-form-input
                    v-model="payload.payer_address"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :placeholder="('Invoicing address')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Company number -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code === 'company'"
                #default="validationContext"
                name="Company org.number"
              >
                <b-form-group
                  :label="$t('Company org.number')"
                  label-for="Company-org.number"
                >
                  <b-form-input
                    v-model="payload.company_number"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="('Company org.number')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Responsible -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code === 'company'"
                #default="validationContext"
                name="Responsible / contact person"
              >
                <b-form-group
                  :label="('Responsible / contact person')"
                  label-for="Resposible-contact-person"
                >
                  <b-form-input
                    v-model="payload.responsible"
                    autofocus
                    :state="getValidationState(validationContext)"
                    :placeholder="('Responsible / contact person')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Phone number -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code !== 'student'"
                #default="validationContext"
                name="Phone number"
              >
                <b-form-group
                  :label="('Phone number')"
                  label-for="Phone-number"
                >
                  <b-form-input
                    v-model="payload.payer_tel"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="('Phone number')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Email address -->
            <b-col
              cols="12"
              sm="6"
            >
              <validation-provider
                v-if="payload.who_pays && payload.who_pays.code !== 'student'"
                #default="validationContext"
                name="Email address"
                rules="email"
              >
                <b-form-group
                  :label="('Email address')"
                  label-for="Email-address"
                >
                  <b-form-input
                    v-model="payload.payer_email"
                    autofocus
                    type="email"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="('Email address')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Method -->
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group
                label="Payment method"
                label-for="payment-method"
              >
                <v-select
                  v-model="payload.paimentMethod"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="methodOptions.filter(el => el.id)"
                  label="name"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
            <!-- Paiment status -->
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group
                label="Payment status"
                label-for="payment-status"
              >
                <v-select
                  v-model="payload.registrationFilter"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="paidOptions.filter(el => el.id && !el.filtered)"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t('Update') }}
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormInput, BButton, BTable, BForm, BLink, VBPopover,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BInputGroup,
  BFormCheckbox, BSpinner, VBTooltip, BFormInvalidFeedback, BAlert, BInputGroupPrepend,
  BFormTextarea,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import {
  ref, onUnmounted, onMounted, watch,
  } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, email,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import router from '@/router'
// import UsersListFilters from './UsersListFilters.vue'
import ChatModule from '@/views/admin/ChatModule.vue'
import CoursesListBigModal from '@/views/courses/admin/courses-list/CoursesListBigModal.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../registerStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import userSelectActions from './UserSelectActions.vue'
import RegistrationsFilter from './RegistrationsFilter.vue'

export default {
  name: 'RegistrationssList',
  components: {
    ValidationProvider,
    ValidationObserver,
    CoursesListBigModal,

    // UsersListFilters,
    UserListAddNew,
    RegistrationsFilter,
    userSelectActions,
    ChatModule,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BForm,
    BFormInvalidFeedback,
    BAlert,
    BLink,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,

    vSelect,
    flatPickr,
    quillEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    singleCourseId: {
      type: Number,
      required: false,
      default: null,
    },
    dashboard: {
      type: Boolean,
      default: false,
    },
    studentId: {
      type: Number,
      default: null,
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      refund: {
        reason: null,
        amount: null,
      },
      isChatActive1: false,
      conversation: {},
      messages: {},
      required,
      email,
      students: [],
      isTypingStudent: false,
      options: {
        block: {
          blocks: [3, 3, 3],
          uppercase: true,
        },
      },
      // isTypingCourse: false,
      // courses: [],
    }
  },
  mounted() {
    if (typeof this.singleCourseId !== 'undefined') {
      this.courseId = this.singleCourseId
    }
    /* eslint-disable camelcase, no-param-reassign, no-restricted-globals, radix, camelcase, no-shadow, no-inner-declarations */
    let isShift = false
    const seperator = '/'
    if (document.getElementById('tblForm')) {
      const inputs = document.getElementById('tblForm').getElementsByTagName('input')
      function IsNumeric(input, keyCode) {
        if (keyCode === 16) isShift = true
        // Allow only Numeric Keys.
        if (((keyCode >= 48 && keyCode <= 57) || keyCode === 8 || keyCode <= 37 || keyCode <= 39 || (keyCode >= 96 && keyCode <= 105)) && isShift === false) {
            if ((input.value.length === 2 || input.value.length === 5) && keyCode !== 8) {
                input.value += seperator
                this.initialDate = input.value
            }
            return true
        }
        return false
      }
      function ShowHideError(textbox, display) {
          const row = textbox.parentNode.parentNode
          const errorMsg = row.getElementsByTagName('small')[0]
          if (errorMsg !== null) errorMsg.style.display = display
      }
      function ValidateDateFormat(input, keyCode) {
        let dateString = input.value
          if (dateString.length === 10) {
            const PureInput = dateString.replace('/', '').replace('/', '')
            input.value = `${PureInput.slice(0, 2)}/${PureInput.slice(2, 4)}/${PureInput.slice(4, 8)}`
            dateString = input.value
          }
          if (keyCode === 16) isShift = false
          const regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/
          // Check whether valid dd/MM/yyyy Date Format.
          if (regex.test(dateString) || dateString.length === 0) {
              ShowHideError(input, 'none')
              document.getElementById('dobInput').classList.remove('is-invalid')
              document.getElementById('dobInput').classList.add('is-valid')
          } else {
              ShowHideError(input, 'block')
              document.getElementById('dobInput').classList.remove('is-valid')
              document.getElementById('dobInput').classList.add('is-invalid')
          }
      }
      // Loop through all INPUT elements.
      for (let i = 0; i < inputs.length; i += 1) {
        // Check whether the INPUT element is TextBox.
        if (inputs[i].type === 'text') {
          // Check whether Date Format Validation is required.
          if (inputs[i].className.indexOf('date-format') !== 1) {
              // Set Max Length.
              inputs[i].setAttribute('maxlength', 10)
              // Only allow Numeric Keys.
              inputs[i].onkeydown = function (e) {
                  return IsNumeric(this, e.keyCode)
              }
              // Validate Date as User types.
              inputs[i].onkeyup = function (e) {
                ValidateDateFormat(this, e.keyCode)
              }
          }
        }
      }
    }
  },
  methods: {
    validateInput(event) {
      const charCode = (event.which) ? event.which : event.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        event.preventDefault()
      }
    },
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item && this.selectedRows.some(el => el.id === item.id)) return ['b-table-row-selected', 'cursor-pointer']
      return ['cursor-pointer']
    },
    /* eslint-disable camelcase */
    autoCompleteStudent(search, role_name) {
      this.isTypingStudent = true
      this.$http.post('/autocomplete/user', { search, role_name })
      .then(res => {
        this.isTypingStudent = false
        this.students = res.data
      })
    },

    // autoCompleteCourse(search) {
    //   this.isTypingCourse = true
    //   this.$http.post('/autocomplete/course', { search })
    //   .then(res => {
    //     this.isTypingCourse = false
    //     this.courses = res.data
    //   })
    // },
    selectAllRows() {
      this.$store.state.registerStoreModule.registrations.rows.forEach(el => {
        this.selectedRows.push(el)
        this.emailsList.push(`${el.student_name} <${el.student_email}>`)
      })
    // this.$refs.refUserListTable.selectAllRows()
    },
      copyEmails(data) {
      this.$copyText(data).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Emails copied',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    clearSelected() {
      this.selectedRows = []
      this.emailsList = []
      // this.$refs.refUserListTable.clearSelected()
    },
    handleChatClick1(id) {
      const userData = JSON.parse(localStorage.getItem('userData'))
      this.$http.post('/conversation', { participants: [id], user_id: userData.id })
      .then(res => {
        this.isChatActive1 = true
        this.conversation = {
          id: res.data.id,
          avatar: res.data.avatar,
          name: res.data.name,
        }
        this.messages = {
          data: res.data.messages,
        }
      })
    },
  },
  setup(props) {
    /* eslint-disable camelcase */
    const getModal1Id = id => `modal1${id}`
    const getModal2Id = id => `modal2${id}`
    const getModal3Id = id => `modal3${id}`
    const getModal4Id = id => `modal4${id}`
    const getModal5Id = id => `modal5${id}`
    const getModal6Id = id => `modal6${id}`
    const getModal7Id = id => `modal7${id}`

    const USER_APP_STORE_MODULE_NAME = 'registrations'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)
    watch(() => props.refreshKey, () => {
      refetchData()
    })

    const query1 = ref('')
    const query2 = ref('')
    const selectedCourse = ref({})
    const courses = ref([])
    const sendEmail = ref(false)
    // Use toast
    const toast = useToast()

    const {
      payerOptions,
      fetchPayers,
      resetFilter,
      dateStart,
      dateEnd,
      initialDate,
      unselectedRows,
      selectAll,
      isBusy,
      regStatusOptions,
      regDefaultStatus,
      getExcelDoc,
      isLoadingExcelDoc,
      paymentFilter,
      fetchUsers,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      selectedRows,
      student,
      course,
      student_id,
      // statuss,
      visa,
      who_pays,
      courseId,
      statusOptions,
      defaultStatus,

      // Course modal
      classrooms,
      teachers,
      masters,
      // cities,
      TofDArray,
      statusArray,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      currentCourseData,
      msgs,
      UsersToAdd,
      UsersAlreadyAdded,
      isBigModalActive,
      spinner,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    } = useUsersList()
    // if (props.dashboard) { defaultStatus.value = 'pending' }
    if (props.studentId) { student_id.value = props.studentId }
    const loadSpinner = () => {
      // open
      spinner.value = true
      setTimeout(() => {
        spinner.value = false
      }, 1000)
    }
    const payload = ref({})
    const getData = item => {
      payload.value = { ...item }
      payload.value.invoice = payload.value.invoice ? payload.value.invoice : { sent_date: null }
      payload.value.who_pays = payload.value.payer
    }

    const errors = ref({
      paid_date: [],
    })

    const refundReason = ref(null)
    const refundComments = ref(null)
    const refundAmount = ref(null)
    const refundFull = ref(false)
    const refundCancel = ref(false)
    const tobeRefundedCancel = ref(false)
    const cancelRefund = ref(false)
    const isTobeRefunded = ref(false)

    const submitRefund = function (registration_id, registration_status) {
      const payload = {
        registration_id,
        amount: refundAmount.value,
        reason: refundComments.value,
        full: refundFull.value,
      }
      store.dispatch('registerStoreModule/refund', payload)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refundCancel.value = true
          if (registration_status !== 'canceled') return this.$bvModal.show(`modal4${registration_id}`)
          return refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const tobeRefunded = function (registration_id, registration_status) {
      const payload = { registration_id, reason: refundReason.value }
      store.dispatch('registerStoreModule/tobeRefunded', payload)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        if (registration_status !== 'canceled') return this.$bvModal.show(`modal4${registration_id}`)
        isTobeRefunded.value = false
        return refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }

    const restoreReg = id => {
      store.dispatch('registerStoreModule/restoreReg', id)
      .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const markAsPaidPending = (id, paid) => {
      const item = { id, paid }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const markAs = (id, payment_status_id) => {
      const item = { id, payment_status_id }
      store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
    }
    const updatePayment = function (element) {
      /* eslint-disable no-nested-ternary */
      if (this.$refs.form.flags.valid) {
        const item = {
          id: element.invoice.id,
          student_id: element.student_name.id === undefined ? element.student_id : element.student_name.id,
          course_id: element.course_name.id === undefined ? element.course_id : element.course_name.id,
          sent_date: element.sent_date,
          paid_date: element.paid_date,
          paid_method_id: element.paimentMethod ? element.paimentMethod.id : null,
          // paid: element.paid ? element.paid.value : null,
          payer_id: element.who_pays ? element.who_pays.id : null,
          payer_name: element.payer_name,
          company_number: element.company_number,
          payer_address: element.payer_address,
          responsible: element.responsible,
          payer_phone: element.payer_tel,
          payer_email: element.payer_email,
          payment_status_id: element.registrationFilter ? element.registrationFilter.id : null,
          // payment_method_id: element.paimentMethod.id,
        }
        store.dispatch('invoiceStoreModule/editInvoice', { item })
          .then(res => {
            toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: 'AlertTriangleIcon',
                variant: 'success',
              },
            })
            refetchData()
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          errors.value = error.response.data.errors
        })
      }
    }
    const emailsList = ref([])
    const onRowSelected = item => {
      // if (unselectedRows.value.includes(item.id)) {
      //   unselectedRows.value.splice(selectedRows.value.indexOf(item.id), 1)
      // }
      // if (selectedRows.value.includes(item.id)) {
      //   selectedRows.value.splice(selectedRows.value.indexOf(item.id), 1)
      //   unselectedRows.value.push(item.id)
      //   emailsList.value.splice(emailsList.value.indexOf(item.id), 1)
      // } else {
      //   selectedRows.value.push(item.id)
      //   emailsList.value.push(`${item.student_name} <${item.student_email}>`)
      // }
      const idToCheck = item.id
      if (selectedRows.value.some(el => el.id === idToCheck)) {
        // If already selected, remove it from selectedRows and add it to unselectedRows
        selectedRows.value = selectedRows.value.filter(el => el.id !== idToCheck)
        unselectedRows.value.push(item)
        emailsList.value.splice(emailsList.value.indexOf(idToCheck), 1)
      } else if (unselectedRows.value.some(el => el.id === idToCheck)) {
        // If already unselected, remove it from unselectedRows and add it to selectedRows
        unselectedRows.value = unselectedRows.value.filter(el => el.id !== idToCheck)
        selectedRows.value.push(item)
        emailsList.value.push(`${item.student_name} <${item.student_email}>`)
      } else {
        // If not selected or unselected, add it to selectedRows
        selectedRows.value.push(item)
        emailsList.value.push(`${item.student_name} <${item.student_email}>`)
      }
    }
    const emailValueSubject = ref(null)
    const emailValueBody = ref(null)
    /* eslint-disable object-curly-newline */
    const sendEmailAction = registrations => {
      const action = 'send-email'
      const unselected = unselectedRows.value.length > 0 ? unselectedRows.value.map(el => el.id) : []
      const isAllSelected = selectAll.value
      const course_id = selectedCourse.value.id
      const subject = emailValueSubject.value
      const body = emailValueBody.value
      store.dispatch('registerStoreModule/multiAction', { registrations, action, course_id, subject, body, unselected, isAllSelected })
      .then(response => {
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }
    /* eslint-disable */
    const multiAction = (action, send_email) => {
      const registrations = selectedRows.value.length > 0 ? selectedRows.value.map(el => el.id) : []
      const unselected = unselectedRows.value.length > 0 ? unselectedRows.value.map(el => el.id) : []
      const isAllSelected = selectAll.value
      const course_id = selectedCourse.value.id
      const subject = emailValueSubject.value
      const body = emailValueBody.value
      store.dispatch('registerStoreModule/multiAction', { registrations, action, course_id, subject, body, send_email, unselected, isAllSelected })
      .then(response => {
        response.data.message.forEach(message => {
          toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        if (action !== 'send-email' && action !== 'generate-contract') refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'FrownIcon',
            variant: 'danger',
          },
        })
      })
    }
    const tests = ref([])
    const selectedTest = ref({})
    const due_date = ref(null)
    const isLoadedTests = ref(false)
    const methodOptions = ref([])
    const getPaymentMethod = () => {
      store.dispatch('registerStoreModule/getPaymentMethod').then(res => {
        methodOptions.value = res.data.rows
        methodOptions.value.unshift({ name: 'All methods', id: null })
         if (router.currentRoute.query.p_method) paymentFilter.value = methodOptions.value.find(el => el.id === parseInt(router.currentRoute.query.p_method, 0))
      })
    }
    const getRegFilters = () => {
      store.dispatch('registerStoreModule/getRegFilters').then(res => {
        statusOptions.value = res.data.rows
        // defaultStatus.value = statusOptions.value.find(el => el.name === 'Paid')
        statusOptions.value.unshift({ name: 'All statuses', id: null })
        if (router.currentRoute.query.p_status) defaultStatus.value = statusOptions.value.find(el => el.id === parseInt(router.currentRoute.query.p_status, 0))
      })
    }
    onMounted(getPaymentMethod)
    onMounted(getRegFilters)
    const getPlacementTest = () => {
      store.dispatch('registerStoreModule/getPlacementTest')
      .then(response => {
        tests.value = response.data.rows
        isLoadedTests.value = true
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const regToWaitingList = id => {
      store.dispatch('registerStoreModule/regToWaitingList', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const sendAdmissionLetter = id => {
      store.dispatch('registerStoreModule/sendAdmissionLetter', id)
      .then(response => {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while sending admission letter',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    /* eslint-disable no-shadow */
    const sendPTest = (students, placement_test_id, due_date) => {
      store.dispatch('registerStoreModule/sendPTest', { students, placement_test_id, due_date })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const moveStudent = studentId => {
      const course_id = selectedCourse.value.id
      store.dispatch('registerStoreModule/moveStudent', { studentId, course_id })
      .then(response => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const isTypingCourse = ref(false)
    const autoCompleteCourse = search => {
      isTypingCourse.value = true
      store.dispatch('registerStoreModule/autoCompleteCourses', { search })
      .then(response => {
        courses.value = response.data
        isTypingCourse.value = false
        })
    }
    const generateContract = id => {
      store.dispatch('registerStoreModule/generateContract', id)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating contract registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const generateCertificate = genId => {
      store.dispatch('registerStoreModule/generateCertificate', genId)
      .then(res => {
        toast({
          component: ToastificationContent,
          props: {
            title: res.data.message,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while creating certificate registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }
    const confirm = confId => {
      store.dispatch('registerStoreModule/confirmRegistration', confId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been confirmed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while confirming registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        })
    }

    const cancel = function (id, send_email) {
      store.dispatch('registerStoreModule/cancelRegistration', { id, send_email, tobeRefunded: tobeRefundedCancel.value })
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          if (cancelRefund.value) return this.$bvModal.show(`refundModal${id}`)
          return refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while canceling registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const proofParticipation = id => {
      store.dispatch('registerStoreModule/proofParticipation', id)
        .then(res => {
          toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
      }).catch(err => {
        toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const remove = regId => {
      store.dispatch('registerStoreModule/deleteRegistration', regId)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Registration Has Been removed',
              icon: 'AlertTriangleIcon',
              variant: 'success',
            },
          })
          refetchData()
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error while removing registration',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    const isChatActive = ref(false)
    const chatModelId = ref(null)
    const handleChatClick = data => {
      isChatActive.value = true
      chatModelId.value = data
    }
    const isFilterActive = ref(false)
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation({})

    const formatDateTime = dateTime => {
      if (typeof dateTime !== 'undefined') {
        const date_ob = new Date()

// adjust 0 before single digit date
        const date = (`0${date_ob.getDate()}`).slice(-2)
// current month
        const month = (`0${date_ob.getMonth() + 1}`).slice(-2)
// current year
        const year = date_ob.getFullYear()
// current hours
        const hours = date_ob.getHours() < 10 ? `0${date_ob.getHours()}` : date_ob.getHours()
// current minutes
        const minutes = date_ob.getMinutes() < 10 ? `0${date_ob.getMinutes()}` : date_ob.getMinutes()
// current seconds
//         const seconds = date_ob.getSeconds()
// prints date & time in YYYY-MM-DD HH:MM:SS format
        /* eslint-disable-next-line */
        // console.log(year + '-' + month + '-' + date + ' ' + hours + ':' + minutes + ':' + seconds)
        return `${date}-${month}-${year} ${hours}:${minutes}`
      }
      return ''
    }
    return {
      refundComments,
      tobeRefundedCancel,
      cancelRefund,
      refundCancel,
      refundReason,
      refundAmount,
      refundFull,
      submitRefund,
      tobeRefunded,
      isTobeRefunded,
      payerOptions,
      fetchPayers,
      resetFilter,
      dateStart,
      dateEnd,
      proofParticipation,
      restoreReg,
      initialDate,
      unselectedRows,
      selectAll,
      isBusy,
      markAs,
      regStatusOptions,
      regDefaultStatus,
      getData,
      payload,
      refFormObserver,
      getValidationState,
      resetForm,

      isLoadingExcelDoc,
      getExcelDoc,
      paymentFilter,
      emailValueSubject,
      emailValueBody,
      emailsList,
      sendEmailAction,
      getPlacementTest,
      tests,
      selectedTest,
      due_date,
      isLoadedTests,

      getPaymentMethod,
      getRegFilters,
      methodOptions,

      updatePayment,
      markAsPaidPending,
      errors,
      // course modal
      isBigModalActive,
      classrooms,
      teachers,
      masters,
      // cities,
      TofDArray,
      statusArray,
      UsersToAdd,
      currentUser,
      suggestClassrooms,
      suggestTeacher,
      ModalData,
      UsersAlreadyAdded,
      spinner,
      loadSpinner,
      currentCourseData,
      msgs,

      // Modal ids
      getModal1Id,
      getModal2Id,
      getModal3Id,
      getModal4Id,
      getModal5Id,
      getModal6Id,
      getModal7Id,

      student,
      course,
      // statuss,
      visa,
      who_pays,

      isChatActive,
      chatModelId,
      handleChatClick,

      sendEmail,

      // Actions..
      remove,
      cancel,
      confirm,
      generateCertificate,
      generateContract,
      moveStudent,
      sendPTest,
      regToWaitingList,
      sendAdmissionLetter,
      selectedRows,
      multiAction,
      onRowSelected,

      isFilterActive,

      // autocomplete
      query1,
      query2,
      selectedCourse,
      courses,
      autoCompleteCourse,
      formatDateTime,

      isTypingCourse,

      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      tableColumns,
      tableColumns1,
      tableColumns2,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      courseId,
      statusOptions,
      defaultStatus,

      // UI
      resolveUserStatusVariant,
      resolveVisaStatusVariant,
    }
  },
}
</script>

<style lang="scss">
.b-table-row-selected {
  background-color: #f4f4f4;
}
.cursor-pointer {
  cursor: pointer;
}
.per-page-selector {
  width: 90px;
}
.list-filter {
  min-width: 270px;
}
// .list-filter1 {
//   min-width: 100px;
// }
.table-secondary {
  opacity: 65%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
